import profilePhoto from './assets/images/profil.jpg'
import facebookSVG from './assets/images/facebook.svg';
import instagramSVG from './assets/images/instagram.svg';
import linkedinSVG from './assets/images/linkedin.svg';
import ethereumSVG from './assets/images/ethereum.svg';
import './App.css';

function App() {
  return (
    <div className="content">
      <div className='profile-photo'><img src={profilePhoto} alt="Profile" /></div>
      <h1 className='name'>Narcis Cotaie</h1>
      <h2 className='occupation'>Software Developer</h2>
      <div className='about'>
        <p>I'm a versatile software developer skilled in PHP, Laravel, React, Node.js, and Solidity. I love tackling new challenges and quickly mastering emerging technologies.</p>
        <p>Outside of coding, I enjoy spending time with my family and going on motorcycle rides.</p>
      </div>
      <div className='socials'>
        <a href='https://facebook.com/narcis.cotaie' target='_blank' rel="noreferrer"><img src={facebookSVG} alt="Facebook" /></a>
        <a href='https://instagram.com/narciscotaie' target='_blank' rel="noreferrer"><img src={instagramSVG} alt="Instagram" /></a>
        <a href='https://www.linkedin.com/in/narciscotaie/' target='_blank' rel="noreferrer"><img src={linkedinSVG} alt="LinkedIn" /></a>
        <a href='https://eips.ethereum.org/EIPS/eip-6808' target='_blank' rel="noreferrer"><img src={ethereumSVG} alt="Ethereum" /></a>
      </div>
    </div>
  );
}

export default App;
